.tile {
    display: flex;
    flex-direction: column;
    padding: 5px 15px 15px 0;
    width: 70%;
    min-width: 500px;
}

.title {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 2px;
    font-weight: bold;
}

.list {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

.item {
    margin-top: 10px;
}

@media only screen and (max-width:900px) {
    .tile {
        width: 100%;
        min-width: 300px;
    }
}
