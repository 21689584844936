.App {
  display: flex;
  flex-direction: row;
  padding-top: 50px;
  padding-left: 10%;
}

@media only screen and (max-width:600px) {
  .App {
    padding: 0px 0px;
    flex-direction: column;
  }
}
