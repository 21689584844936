.selfPortrait {
    width: 350px;
    margin-top: 55px;
    float: right;
}

@media only screen and (max-width:600px) {
    .selfPortrait {
        margin-top: 20px;
        width: 200px;
    }
}