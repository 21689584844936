.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0px 50px;
    padding-top: 10%;
    width: 130px;
    height: 50%;
}

.link {
    display: flex;
    align-content: center;
    padding-left: 15px;
    min-width: 100px;
    height: 30px;
    color: #000000;
    align-items: center;
    text-decoration: none;
}

.contactContainer {
    margin-top: 10px;
    border: 2px solid;
    overflow: hidden;
}

.contactTitle {
    width: 100%;
    padding: 5px 0 8px 15px;
    background: black;
    color: white;
    cursor: default;
}

.icons {
    display: flex;
    padding: 10px 0 5px 5px;
    flex-direction: row;
    justify-content: space-around;
}

.inIcon {
    height: 25px;
}

.emailIcon {
    height: 25px;
}

.link:hover {
    background-color: gray;
    color: #ffffff;
}

.activeLink {
    background-color: #000000;
    color: #ffffff;
}

.cartoonFace {
    width: 100%;
    height: auto;
    margin-left: auto;
    margin-bottom: 15px;
}

@media only screen and (max-width:600px) {
    .container {
        width: 25%;
    }
}